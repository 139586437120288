import axios from '@/libs/axios'
// '@/libs/axios'
const userData = JSON.parse(localStorage.getItem('userData'))
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    roles: [],
    permissions: [],
    total: 0,
  },
  getters: {
    getRoles: (state) => (params) => {

      const { q = '', perPage = 10, page = 1, sortBy = 'name', sortDesc = false  } = params

      const queryLowered = q.toLowerCase()
      const filteredData = state.roles.filter(
        role =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (role.name.toLowerCase().includes(queryLowered) ||
          role.users.includes(queryLowered) ||
          role._createdAt.toLowerCase().includes(queryLowered)) 
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        roles: paginateArray(sortedData, perPage, page),
        total: filteredData.length
      }
    },
    getAllRoles: (state) => {
        return state.roles.map(({ id, name, permissions }) => ({ id:id, name: name, permissions: permissions, }) )
    },
    getPermissions: (state) => {
        return state.permissions.sort(sortCompare('resource'))
    }
  },
  mutations: {
    updateRoles:  (state, roles) => {
      //
      state.roles = roles
      state.total = roles.length
    },
    updatePermissions: (state, permissions) => {
        //
        state.permissions = permissions
    },
    updateRole: (state, role) => {
      //
      state.role = role
    }
  },
  actions: {
    fetchRoles({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roles/${userData.church}`)
          .then(response => { 
            
            //
            commit('updateRoles', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    fetchPermissions({ commit }, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/permissions/`)
            .then(response => { 
              
              //
              commit('updatePermissions', response.data)
              resolve(response)
            
            })
            .catch(error => reject(error))
        })
    },
    fetchRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/role/${userData.church}/${id}`)
          .then(response => { 

            //
            commit('updateRole', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    addRole(ctx, { role }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/role', { ...role, church: userData.church })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRole(ctx, { name, role }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/role/${userData.church}/${name}/${role.id}`, { ...role })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delRole(ctx, { role }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/role/${userData.church}/${role.id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
