import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'role', sortable: false },
    { key: 'fullName', sortable: true },
    { key: 'username', sortable: false },
    { key: 'email', sortable: true },
    { key: 'contact', sortable: true },
    { key: 'roleAccess', sortable: false },
    { key: 'active', label: 'Status', sortable: false },
    { key: '_createdAt', label: 'created', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('fullName')
  const isSortDirDesc = ref(false)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const getUsers = computed(() => {
    const response =  store.getters['settings-user/getUsers']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role: roleFilter.value,
      active: statusFilter.value,
    })

    const { users, total } = response
    totalUsers.value = total

    return users
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('settings-user/fetchUsers')
      .then(() => {
        // const { Users, total } = response.data

        // callback(Users)
        
        // totalUsers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Users' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const blankUserData = {
    fullName: '',
    username: '',
    role: null,
    roleAccess: null,
    email: '',
    country: '',
    contact: '',
    password: '',
    active: false,
  }

  const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

  const clearUserData = () => {
    userData.value = JSON.parse(JSON.stringify(blankUserData))
  }
  const editUser = (userEvent) => {
    
    userData.value = JSON.parse(JSON.stringify(Object.assign(userEvent)))
    // const { userData } = data
    // emit('update:is-add-new-user-sidebar-active', true)
    isAddNewUserSidebarActive.value = true
    // console.log(userEvent)

    userData.value = grabDataFromEditUser(userEvent)
    userData.value.roleAccess = store.getters["settings-role/getAllRoles"].find(rl => rl.name === userData.value.roleAccess)
  }

  const grabDataFromEditUser = userData => {

    const {
      id,
      fullName,
      username,
      role,
      roleAccess,
      email,
      contact,
      active,
    } = userData

    return {
      id,
      fullName,
      username,
      role,
      roleAccess,
      email,
      contact,
      active,
    }
  }

  // ------------------------------------------------
  // addUser
  // ------------------------------------------------
  const addUser = eventData => {
    store.dispatch('settings-user/addUser', { user: eventData }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchUsers()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      isAddNewUserSidebarActive.value = false
 
    }).catch(error=> {

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }

  // ------------------------------------------------
  // updateUser
  // ------------------------------------------------
  const updateUser = eventData => {
    
    store.dispatch('settings-user/updateUser', { user: eventData }).then(response => {
      
      fetchUsers()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      isAddNewUserSidebarActive.value = false
    }).catch(error=> {

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  const updateUserActive = eventData => {
    
    store.dispatch('settings-user/updateUserActive', { user: eventData }).then(response => {
      
      fetchUsers()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  const delUser = eventData => {
    store.dispatch('settings-user/delUser', { user: eventData }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchUsers()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const fetchRoles = (ctx, callback) => {
    store
      .dispatch('settings-role/fetchRoles')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching payments list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const genUserReport =  () => {
    //
    store.dispatch('settings-user/genUserReport').then(() => {
      // eslint-disable-next-line no-use-before-define
      // fetchReceipts()

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: response.data,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    })
  }


  const isAddNewUserSidebarActive = ref(false)

  const resolveUserStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
    return 'warning'
  }

  const resolveRangeVariant = type => {
    if (type === 'today') return 'Today'
    if (type === 'yesterday') return 'Yesterday'
    // if (type === 'this week') return 'This Week'
    // if (type === 'previous week') return 'Previous Week'
    if (type === 'this month') return 'This Month'
    if (type === 'previous month') return 'Previous Month'
    if (type === 'this year') return 'This Year'
    if (type === 'previous year') return 'Previous Year'
  }

  const resolveRoleTypeVariant = type => {
    if (type === 'admin') return 'Admin'
    if (type === 'user') return 'User'
  }

  const resolveTypeRoleIcon = role => {
    if (role === 'admin') return 'UserCheckIcon'
    if (role === 'user') return 'UserIcon'
    return ''
  }

  return {
    // onSubmit,
    getUsers,
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    statusFilter,
    roleFilter,


    resolveRangeVariant,
    resolveUserStatusVariant,
    refetchData,
    resolveRoleTypeVariant,
    resolveTypeRoleIcon,
    isAddNewUserSidebarActive,
    addUser,
    updateUser,
    updateUserActive,
    delUser,
    clearUserData,
    editUser,
    userData,
    fetchRoles,
    genUserReport
  }
}
