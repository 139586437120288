import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRolesList() {
  // Use toast
  const toast = useToast()

  const refRoleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'name', sortable: false },
    { key: 'permissions', sortable: true },
    { key: 'users', sortable: false },
    { key: '_createdAt', label: 'created', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalRoles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refRoleListTable.value ? refRoleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoles.value,
    }
  })

  const getRoles = computed(() => {
    const response =  store.getters['settings-role/getRoles']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })

    const { roles, total } = response
    totalRoles.value = total

    return roles
  })

  const getAllRoles = computed(() => {
    const response =  store.getters['settings-role/getAllRoles']
 
    return response
  })

  const getPermissions = computed(() => {
    const permissions =  store.getters['settings-role/getPermissions']
    return permissions
  })

  const refetchData = () => {
    refRoleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchRoles = (ctx, callback) => {
    store
      .dispatch('settings-role/fetchRoles')
      .then(() => {
        // const { Roles, total } = response.data

        // callback(Roles)
        
        // totalRoles.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Roles' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const blankRoleData = {
    name: '',
    permissions: null,
  }

  const roleData = ref(JSON.parse(JSON.stringify(blankRoleData)))

  const clearRoleData = () => {
    roleData.value = JSON.parse(JSON.stringify(blankRoleData))
  }
  const editRole = (roleEvent) => {
    
    roleData.value = JSON.parse(JSON.stringify(Object.assign(roleEvent)))
    // const { roleData } = data
    // emit('update:is-add-new-role-sidebar-active', true)
    isAddNewRoleSidebarActive.value = true

    roleEvent.roleName = roleEvent.name

    roleData.value = grabDataFromEditRole(roleEvent)
  }

  const grabDataFromEditRole = roleData => {

    const {
      id,
      roleName,
      name,
      permissions,
    } = roleData

    return {
      id,
      roleName,
      name,
      permissions,
    }
  }

  // ------------------------------------------------
  // addRole
  // ------------------------------------------------
  const addRole = eventData => {
    store.dispatch('settings-role/addRole', { role: eventData }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchRoles()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {

        console.error(error)

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }

  // ------------------------------------------------
  // updateRole
  // ------------------------------------------------
  const updateRole = eventData => {
    
    store.dispatch('settings-role/updateRole', { name: eventData.roleName, role: eventData }).then(response => {
      
      fetchRoles()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      isAddNewRoleSidebarActive.value = false
    }).catch(error=> {

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  const delRole = eventData => {
    store.dispatch('settings-role/delRole', { role: eventData }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchRoles()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const fetchPermissions = (ctx, callback) => {
    store
      .dispatch('settings-role/fetchPermissions')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching payments list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const genRoleReport =  () => {
    //
    store.dispatch('settings-role/genRoleReport').then(() => {
      // eslint-disable-next-line no-use-before-define
      // fetchReceipts()

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: response.data,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    })
  }


  const isAddNewRoleSidebarActive = ref(false)

  const resolveRoleStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
    return 'warning'
  }

  const resolveRangeVariant = type => {
    if (type === 'today') return 'Today'
    if (type === 'yesterday') return 'Yesterday'
    // if (type === 'this week') return 'This Week'
    // if (type === 'previous week') return 'Previous Week'
    if (type === 'this month') return 'This Month'
    if (type === 'previous month') return 'Previous Month'
    if (type === 'this year') return 'This Year'
    if (type === 'previous year') return 'Previous Year'
  }

  const resolveRoleTypeVariant = type => {
    if (type === 'admin') return 'Admin'
    if (type === 'role') return 'Role'
  }

  const resolveTypeRoleIcon = role => {
    if (role === 'admin') return 'RoleCheckIcon'
    if (role === 'role') return 'RoleIcon'
    return ''
  }

  return {
    // onSubmit,
    getRoles,
    getAllRoles,
    getPermissions,
    fetchRoles,
    fetchPermissions,
    tableColumns,
    perPage,
    currentPage,
    totalRoles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRoleListTable,


    resolveRangeVariant,
    resolveRoleStatusVariant,
    refetchData,
    resolveRoleTypeVariant,
    resolveTypeRoleIcon,
    isAddNewRoleSidebarActive,
    addRole,
    updateRole,
    delRole,
    clearRoleData,
    editRole,
    roleData,
    genRoleReport
  }
}
