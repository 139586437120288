<template>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ userLocal.id ? 'Update': 'Add' }} User
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
              <b-row>
                <b-col cols="8">
                  <!-- fullname -->
                  <b-form-group
                    label="Fullname"
                    label-for="register-fullname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Fullname"
                      vid="fullname"
                      rules="required"
                    >
                      <b-form-input
                        id="register-fullname"
                        v-model="userLocal.fullName"
                        name="register-fullname"
                        :state="errors.length > 0 ? false:null"
                        placeholder="John Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <!-- username -->
                  <b-form-group
                    label="Username"
                    label-for="register-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      vid="username"
                      rules="required"
                    >
                      <b-form-input
                        id="register-username"
                        v-model="userLocal.username"
                        name="register-username"
                        :state="errors.length > 0 ? false:null"
                        placeholder="joe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- contact -->
              <b-row>
                    <b-col md="4" sm="4">
                        <b-form-group
                            label="Country"
                            label-for="country"
                            >
                              <vue-country-code
                                  class=" is-size-5"
                                  @onSelect="onSelect"
                                  v-model="userLocal.country"
                                  :enabledCountryCode="true"
                                  :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                >
                              </vue-country-code>
                        </b-form-group>
                    </b-col>
                    <b-col md="8" sm="8">
                        <b-form-group
                            label="Contact"
                            label-for="contact"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="contact"
                                    rules="required|min:9"
                                >
                                    <b-form-input
                                      v-if="userLocal.id"
                                    id="contact"
                                    v-model="userLocal.contact.tel"
                                    placeholder="XXXXXXXXX"
                                    type="tel"
                                    />
                                    <b-form-input
                                      v-else
                                    id="contact"
                                    v-model="userLocal.contact"
                                    placeholder="XXXXXXXXX"
                                    type="tel"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                    </b-col>
              </b-row>

              
              <!-- email -->
              <b-form-group
                    label="Email"
                    label-for="register-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="register-email"
                        v-model="userLocal.email"
                        name="register-email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
              </b-form-group>

              <!-- password -->
                  <b-form-group
                    v-if="!userLocal.id"
                    label-for="register-password"
                    label="Password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="register-password"
                          v-model="userLocal.password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="register-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
              </b-form-group>

              <b-row>
                <b-col cols="6">
                  <!-- Role -->
                  <validation-provider
                    #default="validationContext"
                    name="Role"
                    rules="required"
                  >
                    <b-form-group
                        label="Role"
                        label-for="role"
                        :state="getValidationState(validationContext)"
                      >
                      <v-select
                          v-model="userLocal.role"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="roleOptions"
                          label="label"
                          :reduce="ser => ser.value"
                          :close-on-select="true"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <!-- Active -->
                  <!-- <validation-provider
                    #default="validationContext"
                    name="Active"
                    rules="required"
                  > -->
                  <!-- 
                        :state="getValidationState(validationContext)" -->
                    <b-form-group
                        label="Active"
                        label-for="active"
                      >
                      
                        <b-form-checkbox
                          v-model="userLocal.active"
                          name="check-button"
                          switch
                          inline
                        >
                          Active
                        </b-form-checkbox>
                      
                        <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback> -->
                    </b-form-group>
                  <!-- </validation-provider> -->
                </b-col>
              </b-row>

            <!-- Role Access -->
            <validation-provider
              v-if="userLocal.id ? true : userLocal.role !== 'admin'"
              #default="validationContext"
              name="Role Access"
              rules="required"
            >
              <b-form-group
                  label="Role Access"
                  label-for="role-access"
                  :state="getValidationState(validationContext)"
                >
                <v-select
                    v-model="userLocal.roleAccess"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="getAllRoles"
                    label="name"
                    :close-on-select="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BRow, BCol, BSidebar, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BFormCheckbox, BFormDatepicker, BFormTextarea, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref, toRefs, computed } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import useRoleList from '../../roles/role-list/useRoleList'
  import useUsersListAddNew from './useUserListAddNew'
  import flatPickr from 'vue-flatpickr-component'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import router from '@/router'

  
  
  export default {
    components: {
      BRow,
      BCol,
      BSidebar,
      BForm,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BFormCheckbox,
      BFormDatepicker,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      flatPickr
    },
    mixins: [togglePasswordVisibility],
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewUserSidebarActive',
      event: 'update:is-add-new-user-sidebar-active',
    },
    props: {
      isAddNewUserSidebarActive: {
        type: Boolean,
        required: true,
      },
      userData: {
        type: Object
      },
      clearUserData: {
        type: Function,
        required: true,
      },
      roleOptions: {
        type: Array,
        required: true,
      },
      statusOptions: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        router
      }
    },
    setup(props, { emit }) {
      
      const clearFormData = ref(null)
      const passwordFieldType = ref(null)

      const { 
        userLocal,
        resetUserLocal,
        onSubmit,
      } = useUsersListAddNew(toRefs(props), clearFormData, emit)

      const passwordToggleIcon  = computed(() => {
        return passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      })

      const { getAllRoles } = useRoleList()
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetUserLocal, props.clearUserData)

      clearFormData.value = clearForm

      const onSelect = ({name, iso2, dialCode}) => {
      
        userLocal.value.country = "+"+dialCode
        
          console.log(name, iso2, dialCode);
      }

      // set Role Access
      setTimeout(()=>{

        if (router.currentRoute.params.role) {
          
          const role = getAllRoles.value.find(rl => rl.name == router.currentRoute.params.role)
          
          props.isAddNewUserSidebarActive = true
          userLocal.value.roleAccess = role
        }

      }, 500)
  
      return {
        userLocal,
        onSubmit,
        resetForm,
        refFormObserver,
        getValidationState,
        getAllRoles,
        passwordToggleIcon,
        passwordFieldType,
        onSelect
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }

  .vue-country-select  {
        width: 100%
    }

    .vue-country-select > .dropdown {
        padding: 0.6em !important;
    }
  </style>
  