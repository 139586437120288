<template>

    <!-- Table Container Card -->
    <b-card
      no-body
    >
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :user-data="userData"
      :clear-user-data="clearUserData"
      :role-options="roleOptions"
      :status-options="statusOptions"
      @add-user="addUser"
      @update-user="updateUser"
    />

      <div class="m-2">
  
        <!-- Table Top -->
        <b-row>
  
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
                class="mr-1"
            >
                Add User
            </b-button>

            <!-- <b-button
              variant="outline-primary"
              class="mr-1"
              @click="genUserReport"
            >
              Export to *.Excel
            </b-button> -->
          </b-col>
  
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="roleFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="role => role.value"
                class="invoice-filter-select mr-1"
                placeholder="Select Role"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="status => status.value"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
  
      </div>
  
      <b-table
        ref="refUserListTable"
        :items="getUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
  
        <!-- Column: Id -->
        <template #cell(id)="data">
          <!-- <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
            class="font-weight-bold"
          > -->
            <!-- #{{ data.value }} -->
            {{ data.index+1 }}
          <!-- </b-link> -->
        </template>

        <template #cell(contact)="data">
          <b-link
            :href="`tel:${data.item.contact.countryCode+data.item.contact.tel}`"
            class="font-weight-bold"
          >
            {{ data.item.contact.countryCode+data.item.contact.tel }}
          </b-link>
        </template>

        <template #cell(email)="data">
          <b-link
            :href="`tel:${data.item.email}`"
            class="font-weight-bold"
          >
            {{ data.item.email }}
          </b-link>
        </template>

        <template #cell(roleAccess)="data">
          <b-link
            v-if="data.item.roleAccess"
            class="font-weight-bold"
            :to="{ name: 'settings-role-list', params: {  role: data.item.roleAccess } }"
          >
            <b-badge
                :variant="`primary`"
                class="text-capitalize"
              >
              {{ data.item.roleAccess.substring(0,1).toUpperCase()+data.item.roleAccess.substring(1)  }}
            </b-badge>
             
          </b-link>
        </template>

        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveTypeRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-primary`"
            />
            <span class="font-weight-bold align-text-top text-capitalize">{{ resolveRoleTypeVariant(data.item.role) }} </span>
          </div>
          </template>

          <template #cell(_date)="data">
          <div class="text-nowrap">
            <span class="font-weight-bold align-text-top text-capitalize">{{ data.item._date }}</span>
          </div>
          </template>

          <template #cell(fullName)="data">
            <b-link
              class="font-weight-bold"
            >
              {{ data.item.fullName  }}
            </b-link>
          </template>

          <template #cell(active)="data">
            <div class="text-nowrap">
              <b-badge
                pill
                @click="updateUserActive(data.item)"
                :variant="`light-${resolveUserStatusVariant(data.item.active)}`"
                class="text-capitalize"
              >
                {{ data.item.active ? 'Active' : 'Inactive' }}
              </b-badge>
            </div>
          </template>
  
        <!-- Column: Actions -->
        <template #cell(actions)="data">
  
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              v-if="data.item.role !== 'admin'"
              variant="link"
              toggle-class=""
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
            
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="editUser(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="editPs(data.item)">
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">Password</span>
                </b-dropdown-item>
                <b-dropdown-item @click="delUser(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
  
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
  
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries </span>
            
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
  
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
  
          </b-col>
  
        </b-row>
      </div>
    </b-card>
  
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BFormTextarea, BFormDatepicker, BFormTimepicker, BForm, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BAlert,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted } from '@vue/composition-api'
  import store from '@/store'
  import useUserList from './useUserList'
  import flatPickr from 'vue-flatpickr-component'
  
  import userStoreModule from '../userStoreModule'
  import router from '@/router'
  import UserListAddNew from '../user-add-new/UserListAddNew.vue'
  import Ripple from 'vue-ripple-directive'
  import roleStoreModule from '../../roles/roleStoreModule'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BFormTextarea,
      BFormDatepicker,
      BFormTimepicker,
      BForm,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BFormGroup,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BTooltip,
      BAlert,
      flatPickr,
      vSelect,
      UserListAddNew,
    },
    directives: {
        Ripple,
    },
    data() {
      return {
        router
      }
    },
    setup() {
  
      const SETTINGS_USER_STORE_MODULE_NAME = 'settings-user'
      const SETTINGS_ROLE_STORE_MODULE_NAME = 'settings-role'
  
      // Register module
      if (!store.hasModule(SETTINGS_USER_STORE_MODULE_NAME)) store.registerModule(SETTINGS_USER_STORE_MODULE_NAME, userStoreModule)
      if (!store.hasModule(SETTINGS_ROLE_STORE_MODULE_NAME)) store.registerModule(SETTINGS_ROLE_STORE_MODULE_NAME, roleStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(SETTINGS_USER_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_USER_STORE_MODULE_NAME)
        if (store.hasModule(SETTINGS_ROLE_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_ROLE_STORE_MODULE_NAME)
      })

      const statusOptions = [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false }
      ]

  
      const roleOptions = [
        { label: 'Admin', value: 'admin' },
        { label: 'User', value: 'user' },
      ]
  
      const {
        fetchUsers,
        approveUsers,
        getUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
  
        roleFilter,
        statusFilter,
  
        refetchData,
        resolveUserStatusVariant,
        resolveRoleTypeVariant,
        resolveTypeRoleIcon,
        isAddNewUserSidebarActive,
        addUser,
        updateUser,
        updateUserActive,
        delUser,
        clearUserData,
        editUser,
        userData,
        fetchRoles,
        genUserReport
        
      } = useUserList()
  
      fetchUsers()

      fetchRoles()

      setTimeout(()=> {
        if (router.currentRoute.params.search) {
          //
          searchQuery.value = router.currentRoute.params.search

        }
      }, 500)
      
      return {
        approveUsers,
        getUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
  
        roleFilter,
        statusFilter,
        refetchData,
        roleOptions,
        statusOptions,
        resolveUserStatusVariant,
        resolveRoleTypeVariant,
        resolveTypeRoleIcon,
        isAddNewUserSidebarActive,
        addUser,
        updateUser,
        updateUserActive,
        delUser,
        clearUserData,
        editUser,
        userData,
        genUserReport
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  