import axios from '@/libs/axios'
// '@/libs/axios'
const userData = JSON.parse(localStorage.getItem('userData'))
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import moment from 'moment'
import { saveAs } from 'file-saver';

export default {
  namespaced: true,
  state: {
    users: [],
    total: 0,
  },
  getters: {
    getUsers: (state) => (params) => {

      const { q = '', perPage = 10, page = 1, sortBy = 'fullName', sortDesc = false, role = null, active = null  } = params

      const queryLowered = q.toLowerCase()
      const filteredData = state.users.filter(
        user =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (user.fullName.toLowerCase().includes(queryLowered) ||
          user.username.toLowerCase().includes(queryLowered) ||
          user.email.toLowerCase().includes(queryLowered) ||
          user.roleAccess.toLowerCase().includes(queryLowered) ||
          user._createdAt.toLowerCase().includes(queryLowered)) 
          &&
          user.role === (role || user.role) &&
          user.active === (active || user.active),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        users: paginateArray(sortedData, perPage, page),
        total: filteredData.length
      }
    }
  },
  mutations: {
    updateUsers:  (state, users) => {
      //
      state.users = users
      state.total = users.length
    },
    updateUser: (state, user) => {
      //
      state.user = user
    }
  },
  actions: {
    fetchUsers({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${userData.church}`)
          .then(response => { 
            
            //
            commit('updateUsers', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/${userData.church}/${id}`)
          .then(response => { 

            //
            commit('updateUser', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    addUser(ctx, { user }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user', { ...user, church: userData.church, user: userData.fullName })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { user }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/user/${userData.church}/${user.id}`, { ...user })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserActive(ctx, { user }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/user/active/${userData.church}/${user.id}`, { fullName: user.fullName, active: user.active  })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delUser(ctx, { user }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/${userData.church}/${user.id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    genUserReport(ctx, memberData) {
      return new Promise((resolve, reject) => {

        const fileName = 'User_Report_'+(new Date().toISOString().substring(0,10))+'_'+new Date().getTime()

          axios.post(`/user/report/${userData.church}`, {
            fileName: fileName
          }, {
              responseType: 'blob'
          }).then((response) => {
              //
              saveAs(response.data, fileName);
          });
      })
    },
  },
}
